<template>
  <div class="flex flex-col text-gray-700 bg-white h-auto w-auto px-4 py-4 rounded-sm shadow">
    <modal
      name="sendEmailModal"
      width="900"
      height="auto"
      :scrollable="true"
    >
      <div class="text-base rounded-sm p-4">
        <div class="mb-4">
          <div>Absender der Mail:</div>
          <input
            required
            v-model="emailSender"
            type="email"
            size="60"
            placeholder="ihr.name@domain.com.net.org.de"
            class="text-sm font-sans rounded-sm px-4 py-1 bg-gray-300 focus:text-gray-900 focus:outline-none focus:shadow-md"
          />
        </div>
        <div class="mb-4">
          <div>Empfänger der Mail:</div>
          <input
            required
            v-model="emailReceiver"
            type="email"
            size="60"
            placeholder="empfänger@domain.com.net.org.de"
            class="text-sm font-sans rounded-sm px-4 py-1 bg-gray-300 focus:text-gray-900 focus:outline-none focus:shadow-md"
          />
        </div>
        <div class="mb-4">
          <div>Nachricht:</div>
          <textarea
            required
            v-model="emailPersonalMessage"
            type="text-area"
            rows="12"
            cols="80"
            placeholder="Persönliche Nachricht...."
            class="text-sm font-sans rounded-sm px-4 py-1 bg-gray-300 focus:text-gray-900 focus:outline-none focus:shadow-md"
          ></textarea>
          <div class="flex">
            <div
              class="w-32 rounded-sm flex px-4 py-1 rounded-sm bg-blue-400 text-gray-800 hover:bg-blue-400 hover:text-gray-900 focus:outline-none shadow-md hover:shadow-lg"
              v-on:click.prevent="SEND_EMAIL"
            >
              <button class="focus:outline-none">Email senden</button>
            </div>
            <div
              class="w-32 ml-2 rounded-sm flex px-4 py-1 rounded-sm bg-purple-400 text-gray-800 hover:bg-red-400 hover:text-gray-900 focus:outline-none shadow-md hover:shadow-lg"
              v-on:click.prevent="$modal.hide('sendEmailModal')"
            >
              <button class="focus:outline-none">Abbrechen</button>
            </div>
          </div>
          <div class="mt-4">Vorschau:</div>
          <pre
            class="bg-blue-100 rounded-sm px-4 py-1 text-sm font-sans"
            id="finalMessage"
          >
#nachricht:
{{emailPersonalMessage}}

#link:
{{downloadLink}}

#dateien: {{totalFiles}} ( {{sizeFiles}})
<div
  v-for="file in completedFiles"
  :key="file.name"
><p>{{file.name}} </p></div>
              </pre>
        </div>
      </div>
    </modal>
    <div class="flex-1 flex-auto py-2 font-bold">
      <img
        class
        src="../assets/img/xcm_logo.png"
        alt="XCM Logo"
      />
    </div>
    <div class="flex-1 flex-auto py-2 font-bold">
      <h1>Dateien für den Upload wählen. (max. 7 auf einmal)</h1>
    </div>
    <div class="flex flex-row py-2">
      <div class="flex-auto rounded mr-2">
        <input
          type="file"
          id="uploadFiles"
          name="file"
          v-on:change="filesChanged"
          multiple="multiple"
          class="flex w-full block px-4 py-1 bg-gray-600 text-gray-100 rounded-sm placeholder-blue-100 focus:outline-none"
        />
      </div>
      <div
        v-if="(uploadFileList.length < 1 || numUploads > 0)"
        class="flex-grow-1 rounded-sm flex px-4 py-1 rounded-sm bg-blue-100 text-gray-400 focus:outline-none"
      >
        <button
          class="cursor-not-allowed"
          disabled
        >upload</button>
      </div>
      <div
        v-else
        class="flex-grow-1 rounded-sm flex px-4 py-1 rounded-sm bg-blue-400 text-gray-800 hover:bg-blue-400 hover:text-gray-900 focus:outline-none shadow-md hover:shadow-lg"
        v-on:click.prevent="uploadFile"
      >
        <button class="focus:outline-none">upload</button>
      </div>
      <div
        class="flex-grow-1 ml-2 rounded-sm flex px-4 py-1 rounded-sm bg-purple-400 text-gray-800 hover:bg-red-400 hover:text-gray-900 focus:outline-none shadow-md hover:shadow-lg"
        v-on:click.prevent="clearAll"
      >
        <button class="focus:outline-none">logout</button>
      </div>
    </div>
    <div
      v-if="totalFiles > 0"
      class="flex-1 flex-auto py-4 font-semibold"
    >
      <div class="mb-1 text-base font-normal">Dateien: {{totalFiles}}, {{sizeFiles}}</div>
      <div class="text-base font-normal text-red-400 shadow px-1">{{ uploadFileList.length > 0 ? ( numUploads > 0 ? 'lade hoch...' : 'Bitte mit [ Upload ] bestätigen oder Datei entfernen...' ) : '' }}</div>
      <div
        v-clipboard:copy="downloadLink"
        v-clipboard:success="copySuccess"
        v-clipboard:error="copyError"
        class="font-normal text-base bg-gray-200 text-blue-600 shadow"
      >{{ uploadFileList.length === 0 && numUploads === 0 ? '[ Zum kopieren des Download-Links hier klicken ]' : '' }}</div>
      <div
        class="font-normal text-base bg-gray-200 text-green-600 shadow"
        v-on:click.prevent="$modal.show('sendEmailModal')"
      >{{ uploadFileList.length === 0 && numUploads === 0 ? '[ Zum versenden des Download-Links per E-Mail hier klicken ]' : '' }}</div>
    </div>
    <div
      v-for="file in uploadFileList"
      :key="file.name"
      class="flex w-full flex-auto flex-row block py-1"
    >
      <div
        class="flex mr-2 rounded-sm flex px-2 rounded-sm bg-red-400 text-gray-800 hover:bg-red-600 hover:text-gray-900 focus:outline-none shadow-md hover:shadow-lg"
        v-on:click.prevent="removeFromUpload(file.name)"
        v-if="file.percent===0"
      >
        <button
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
          class="focus:outline-none text-base font-normal"
        >Upload entfernen</button>
      </div>
      <div
        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
        class="flex text-base font-normal px-2 py-1 rounded-sm"
      >{{file.name}}</div>
      <div
        v-if="file.percent!==0"
        class="flex flex-auto rounded-sm rounded-sm bg-gray-600 ml-2"
      >
        <div
          class="pt-1 bg-green-300 text-center"
          v-bind:style="{width:file.percent+'%'}"
        >{{file.percent}}%</div>
      </div>
    </div>
    <div
      v-if="completedFiles.length > 0"
      class="flex-1 flex-auto font-semibold"
    >
      <div
        v-for="file in completedFiles"
        :key="file.name"
        class="flex w-full flex-auto flex-row block py-1"
      >
        <div
          v-on:click.prevent="removeFromServer(file.name)"
          class="flex-grow-0 mr-2 rounded-sm flex px-2 rounded-sm bg-red-400 text-gray-800 hover:bg-red-600 hover:text-gray-900 focus:outline-none shadow-md hover:shadow-lg"
        >
          <button
            style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            class="focus:outline-none text-base font-normal"
          >Vom Server entfernen</button>
        </div>
        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
          class="text-base font-normal px-2 py-1 rounded-sm"
        >{{file.name}}</div>
        <div class="flex ml-2">✔️</div>
      </div>
    </div>
    <div
      v-if="failedFiles.length > 0"
      class="flex-1 flex-auto font-semibold"
    >
      <div
        class="flex mr-2 rounded-sm flex px-2 rounded-sm bg-red-400 text-gray-800 hover:bg-red-600 hover:text-gray-900 focus:outline-none shadow-md hover:shadow-lg"
        v-on:click.prevent="removeFromFailed(file.name)"
        v-if="file.percent===0"
      >
        <button class="focus:outline-none text-base font-normal">Entfernen</button>
      </div>
      <div
        v-for="file in failedFiles"
        :key="file.name"
        class="flex w-full flex-auto flex-row block py-1"
      >
        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
          class="bg-red-200 text-base font-normal px-2 pt-1 pb-1 rounded-sm"
        >{{file.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "upload",
  components: {},
  data() {
    return {
      uploadFiles: [],
      uploadFileList: [],
      completedFiles: [],
      failedFiles: [],
      totalFiles: 0,
      sizeFiles: "",
      numUploads: 0,
      token: "",
      uuid: "",
      domain: window.location.origin,
      emailReceiver: "",
      emailSender: "",
      emailPersonalMessage: "",
      emailPreview: "",
    };
  },
  mounted() {
    this.uuid = this.uuidv4();
    this.token = this.$store.getters.token;
  },
  computed: {
    downloadLink: function () {
      return this.domain + "/#/download?dl=" + this.uuid;
    },
  },
  methods: {
    SEND_EMAIL: function () {
      let { token, emailReceiver, emailSender } = this;
      if (!emailReceiver || emailReceiver === "" || emailReceiver.length <= 5) {
        this.$toasted.error(
          "E-Mail konnte nich gesendet werden. Empfänger erforderlich!",
          {
            duration: 7500,
          }
        );
        return;
      }
      if (!emailSender || emailSender === "" || emailSender.length <= 5) {
        this.$toasted.error(
          "E-Mail konnte nich gesendet werden. Absender erforderlich!",
          {
            duration: 7500,
          }
        );
        return;
      }
      let message = document.getElementById("finalMessage").textContent;
      return new Promise((resolve, reject) => {
        axios({
          url: "/send_email",
          data: {
            email_receiver: emailReceiver,
            email_sender: emailSender,
            message: message,
          },
          method: "POST",
          headers: { Authorization: token },
        }).then(
          (response) => {
            this.$modal.hide("sendEmailModal");
            this.$toasted.info(
              "E-Mail mit Link zum Download wurde versendet!",
              {
                duration: 5500,
              }
            );
            resolve(response);
          },
          (error) => {
            this.$modal.hide("sendEmailModal");
            this.$toasted.error(
              "E-Mail konnte nich gesendet werden. Bitte Link kopieren und selbst versenden!",
              {
                duration: 7500,
              }
            );
            reject(error);
          }
        );
      });
    },
    uuidv4: function () {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    copySuccess: function () {
      this.$toasted.info("Download-Link in die Zwischenablage kopiert!", {
        duration: 3500,
      });
    },
    copyError: function () {
      this.$toasted.error("download link not copied to clipboard", {
        duration: 3500,
      });
    },
    removeFromUpload: function (name) {
      let x = 0;
      this.uploadFileList = this.uploadFileList.filter(
        (object) => object.name !== name
      );
      this.uploadFiles = this.uploadFiles.filter(
        (object) => object.name !== name
      );
      this.uploadFiles.forEach((f) => {
        x = x + f.size;
      });
      this.completedFiles.forEach((f) => {
        x = x + f.size;
      });
      this.failedFiles.forEach((f) => {
        x = x + f.size;
      });
      this.totalFiles =
        this.uploadFiles.length +
        this.completedFiles.length +
        this.failedFiles.length;
      this.sizeFiles = (x / 1024 / 1024).toFixed(2) + " MB ";
    },
    removeFromServer: function (name) {
      let { token, uuid } = this;
      return new Promise((resolve, reject) => {
        axios({
          url: "/delete",
          data: {},
          method: "POST",
          headers: {
            uuid: uuid,
            Authorization: token,
            Name: encodeURI(name),
          },
        }).then(
          (response) => {
            let x = 0;
            this.completedFiles = this.completedFiles.filter(
              (f) => f.name !== name
            );
            this.uploadFiles.forEach((f) => {
              x = x + f.size;
            });
            this.completedFiles.forEach((f) => {
              x = x + f.size;
            });
            this.failedFiles.forEach((f) => {
              x = x + f.size;
            });
            this.totalFiles =
              this.uploadFiles.length +
              this.completedFiles.length +
              this.failedFiles.length;
            this.sizeFiles = (x / 1024 / 1024).toFixed(2) + " MB ";
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    clearAll: function () {
      this.uploadFiles = [];
      this.uploadFileList = [];
      this.completedFiles = [];
      this.failedFiles = [];
      this.totalFiles = 0;
      this.sizeFiles = "";
      this.numUploads = 0;
      this.$store.commit("SET_TOKEN", { token: "" });
      document.querySelector("#uploadFiles").value = "";
      location.reload();
    },
    filesChanged: function () {
      let x = 0;
      let files = document.querySelector("#uploadFiles").files;
      if (files.length >= 8) {
        document.querySelector("#uploadFiles").value = "";
        return;
      }
      files.forEach((f) => {
        if (
          this.completedFiles.findIndex((x) => x.name === f.name) === -1 &&
          this.uploadFileList.findIndex((x) => x.name === f.name) === -1 &&
          this.uploadFiles.findIndex((x) => x.name === f.name) === -1
        ) {
          this.uploadFileList.push({ name: f.name, size: f.size, percent: 0 });
          this.uploadFiles.push(f);
          this.uploadFiles.forEach((f) => {
            x = x + f.size;
          });
          this.completedFiles.forEach((f) => {
            x = x + f.size;
          });
          this.failedFiles.forEach((f) => {
            x = x + f.size;
          });
          this.totalFiles =
            this.uploadFiles.length +
            this.completedFiles.length +
            this.failedFiles.length;
          this.sizeFiles = (x / 1024 / 1024).toFixed(2) + " MB ";
        } else {
          return;
        }
      });
    },
    uploadFile: function () {
      if (this.numUploads > 0) {
        this.$toasted.error("please wait until the uploads are done", {
          duration: 2000,
        });
        return;
      }
      this.uploadFiles.map((f) => {
        this.numUploads++;
        new Promise((resolve, reject) => {
          axios({
            url: "/upload",
            method: "POST",
            data: {},
            headers: {
              uuid: this.uuid,
              Authorization: this.token,
              Name: encodeURI(f.name),
            },
          }).then((res) => {
            return axios({
              url: res.data.url,
              data: f,
              method: "PUT",
              headers: {
                "Content-Type": f.type,
              },
              onUploadProgress: function (progressEvent) {
                let percent = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                let idx = this.uploadFileList.findIndex(
                  (x) => x.name === f.name
                );
                let file = this.uploadFileList[idx];
                file.percent = percent;
                this.$set(this.uploadFileList, idx, file);
              }.bind(this),
            })
              .then(
                function (response) {
                  this.completedFiles.push(f);
                  this.uploadFileList = this.uploadFileList.filter(
                    (object) => object.name !== f.name
                  );
                  this.uploadFiles = this.uploadFiles.filter(
                    (object) => object.name !== f.name
                  );
                  if (this.uploadFiles.length === 0) {
                    document.querySelector("#uploadFiles").value = "";
                  }
                  this.numUploads--;
                  resolve(response);
                }.bind(this)
              )
              .catch(
                function (error) {
                  this.failedFiles.push(f);
                  this.uploadFileList = this.uploadFileList.filter(
                    (object) => object.name !== f.name
                  );
                  this.uploadFiles = this.uploadFiles.filter(
                    (object) => object.name !== f.name
                  );
                  if (this.uploadFiles.length === 0) {
                    document.querySelector("#uploadFiles").value = "";
                  }
                  this.numUploads--;
                  reject(error);
                }.bind(this)
              );
          });
        });
      });
    },
  },
};
</script>
<style scoped>
pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
</style>